import React, { Component, useState } from "react"
import Header from "../Header2"
import Footer from "../Footer"
import { Helmet } from "react-helmet"
import Buttons from "../Buttons"
import { CardG } from "./Card"
import HeroImg from "../HeroImg"
const Data = [
  {
    id: "1",
    title: "نادي رواس",
    img: "https://i.imgur.com/SoV8qYj.jpeg",
    link: "/gallery/galleryDetails/",
  },
  {
    id: "2",
    title: "مكتبة قرطبة",
    img: "/./assets/Images/QurtubaLib/IMG_4503.jpg",
    link: "/gallery/galleryDetails/",
  },
  {
    id: "3",
    title: "مبادرة اعتماد",
    img: "/./assets/Images/Eatemad/775fa9ac-a37b-44c8-b287-d32b59daa9c6.jpg",
    link: "/gallery/galleryDetails/",
  },

  {
    id: "5",
    title: "برنامج دار أندلسية",
    img: "https://i.imgur.com/MgHI6Be.jpeg",
    link: "/gallery/galleryDetails/",
  },
  {
    id: "6",
    title: "برنامج إكسير",
    img: "	https://i.imgur.com/CiBHT8Y.jpeg",
    link: "/gallery/galleryDetails/",
  },
  {
    id: "7",
    title: "الحفل التطوعي السنوي 'قنطرة'",
    img: "https://i.imgur.com/C2A8sHa.jpeg",
    link: "/gallery/galleryDetails/",
  },
  {
    id: "8",
    title: "صيف قرطبة",
    img: "https://i.imgur.com/gzkt2nk.jpeg",
    link: "/gallery/galleryDetails/",
  },
  {
    id: "9",
    title: "لقاءات الجمعية العمومية",
    img: "/./assets/Images/Meetings/IMG_7888.jpg",
    link: "/gallery/galleryDetails/",
  },
  {
    id: "10",
    title: "المناسبات الداخلية",
    img: "https://i.imgur.com/BKkhizk.jpeg",
    link: "/gallery/galleryDetails/",
  },
  {
    id: "12",
    title: "برنامج سياح",
    img: "https://i.imgur.com/krwU6eM.jpeg",
    link: "/gallery/galleryDetails/",
  },
]

const IndexG = () => {
  const [item, setItem] = useState(Data)

  const menuItems = [...new Set(Data.map(Val => Val.category))]

  const filterItem = curcat => {
    const newItem = Data.filter(newVal => {
      return newVal.category === curcat
    })
    setItem(newItem)
  }
  return (
    <div>
      <Helmet>
        <title> الصور</title>

        <script type="text/javascript" src="/./assets/js/membership.js"></script>
      </Helmet>
      <Header />
      <body>
        <HeroImg title="الصور" />
        <main>
          <CardG item={item} />
          <br />
          <br />
        </main>
        <Footer />
      </body>
    </div>
  )
}
export default IndexG
