
import React, { useState } from "react"
import Header from "../Header2"
import Footer from "../Footer"
import { Helmet } from "react-helmet"
import HeroImg from "../HeroImg"
import "./gallery.css";

const Meetings = () => {
  const images = [
    { id: 1, category: "one", src: "/./assets/Images/Meetings/IMG_6838_jpg.jpg" },
    { id: 9, category: "one", src: "/./assets/Images/Meetings/IMG_7874.jpg" },
    { id: 3, category: "one", src: "/./assets/Images/Meetings/IMG_7885.jpg" },


    { id: 2, category: "one", src: "/./assets/Images/Meetings/IMG_7883.jpg" },
    { id: 10, category: "one", src: "/./assets/Images/Meetings/IMG_7879.jpg" },

    { id: 4, category: "one", src: "/./assets/Images/Meetings/IMG_7887.jpg" },

  ];
  
  const categories = [ "one", "two", "three", "four", "five"];
  const [filter, setFilter] = useState("one");

  const handleFilterChange = (category) => {
    setFilter(category);
  }
  return (
    <div>
    <Helmet>
      <title>لقاءات الجمعية العمومية</title>
    
    
    
    </Helmet>
    <Header />
    
    <body >

    <HeroImg title="لقاءات الجمعية العمومية" />
    <main >
    
<div className="desktop_7">
      <div className="responsive-container-block bigContainer">
        <div className="responsive-container-block Container">
         
          <div className="responsive-container-block imageContainer">
            {images
              .filter((image) => filter === "all" || image.category === filter)
              .map((image) => (
                <div className="project" key={image.id}>
                  <img className={`squareImg ${image.category}`} src={image.src} alt={image.category} />
                
                 
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
      <br/><br/>
    </main>
    <Footer />
    </body>
    </div>

  )
}
export default Meetings
