import React, { useState, useMemo } from "react"
import Pagination from "./Pagination"
import Header from "./Header2"
import Footer from "./Footer"
import { Helmet } from "react-helmet"
import HeroImg from "./HeroImg"
const Data = [
  {
    id: "1",
    title: "زيارة مركز المسؤولية المجتمعية",
    img: "https://i.imgur.com/knCso2A.png",
    link: "https://twitter.com/epcsrsa/status/1387420890934226948?s=21&t=6xa77qV7AKEa-8ZkpSogjQ",
    date: "16 09 1442 هـ",
  },
  {
    id: "2",
    title: "زيارة الأمير محمد بن نايف للتعريف بالجمعية أهدافها",
    img: "https://i.imgur.com/olpJOyg.jpg",
    link: "https://www.moi.gov.sa/wps/portal/Home/emirates/easternprovince/contents/!ut/p/z0/fYy9CsIwFEZfpYtjuTehRhyLWOvPIg7WLCGWS43KbZuE6uPb4ii4fHAOhw80VKDZDq6x0bVsnyNftDK4zbJSZHKPZSEwP64Oa6kKifkcTtbDDvT_aHxx977XOei65UjvCBXZEMlz0vl2cFzTDH8N0yt8N7G-vrlhqkI0kzFCpbhIJYrl1RBD99icP-aW-o4!/",
    date: "12 11 1444 هـ",
  },
  {
    id: "3",
    title: "زيارة مجلس إدارة غرفة الشرقية",
    img: "https://i.imgur.com/HaOm8rz.jpg",
    link: "https://x.com/AsharqiaChamber/status/1432667606696316930?t=wEFgT4IGyz6uyZlPaB2UMA&s=19",
    date: "15 07 1442 هـ",
  },
  {
    id: "4",
    title: "قرطبة... مكتبة موجهة للقارئات من سن 6 إلى 36 عاماً",
    img: "/./assets/Images/DSC09072.jpg",
    link: "https://aawsat.com/home/article/2837201/%C2%AB%D9%82%D8%B1%D8%B7%D8%A8%D8%A9%C2%BB-%D9%85%D9%83%D8%AA%D8%A8%D8%A9-%D9%85%D9%88%D8%AC%D9%87%D8%A9-%D9%84%D9%84%D9%82%D8%A7%D8%B1%D8%A6%D8%A7%D8%AA-%D9%85%D9%86-%D8%B3%D9%86-6-%D8%A5%D9%84%D9%89-36-%D8%B9%D8%A7%D9%85%D8%A7%D9%8B",
    date: "18 07 1442 هـ",
  },
  {
    id: "5",
    title: "قرطبة.. أول جمعية قراءة تطلق مكتبة نسائية",
    img: "/./assets/Images/DSC01022.jpg",
    link: "https://www.alyaum.com/articles/6308687/%D8%A7%D9%84%D8%A3%D8%AE%D8%A8%D8%A7%D8%B1/%D9%85%D9%86%D9%88%D8%B9%D8%A7%D8%AA/%D9%82%D8%B1%D8%B7%D8%A8%D8%A9-%D8%A3%D9%88%D9%84-%D8%AC%D9%85%D8%B9%D9%8A%D8%A9-%D9%82%D8%B1%D8%A7%D8%A1%D8%A9-%D8%AA%D8%B7%D9%84%D9%82-%D9%85%D9%83%D8%AA%D8%A8%D8%A9-%D9%86%D8%B3%D8%A7%D8%A6%D9%8A%D8%A9",
    date: "15 07 1442 هـ",
  },
  {
    id: "6",
    title: "حكاية جمعية قرطبة",
    img: "https://i.imgur.com/lgFWvzc.jpg",
    link: "https://nadiim.com/%D8%AD%D9%83%D8%A7%D9%8A%D8%A9-%D8%AC%D9%85%D8%B9%D9%8A%D8%A9-%D9%82%D8%B1%D8%B7%D8%A8%D8%A9/",
    date: "04 04 1443 هـ",
  },
  {
    id: "7",
    title: "مكتبة 'قرطبة'.. مولود ثقافي سعودي جديد",
    img: "/./assets/Images/Q37.jpg",
    link: "https://asharq.com/culture/4445/%D9%85%D9%83%D8%AA%D8%A8%D8%A9-%D9%82%D8%B1%D8%B7%D8%A8%D8%A9-%D9%85%D9%88%D9%84%D9%88%D8%AF-%D8%AB%D9%82%D8%A7%D9%81%D9%8A-%D8%B3%D8%B9%D9%88%D8%AF%D9%8A-%D8%AC%D8%AF%D9%8A%D8%AF/",
    date: "19 07 1442 هـ",
  },
  {
    id: "8",
    title: "«قرطبة للقراءة» تشارك بمعرض الشرقية للكتاب ",
    img: "https://i.imgur.com/quSKI4I.jpg",
    link: "https://www.alyaum.com/articles/6457002/%D8%A7%D9%84%D8%AB%D9%82%D8%A7%D9%81%D8%A9-%D9%88%D8%A7%D9%84%D9%81%D9%86/%D8%AB%D9%82%D8%A7%D9%81%D8%A9/%D9%82%D8%B1%D8%B7%D8%A8%D8%A9-%D9%84%D9%84%D9%82%D8%B1%D8%A7%D8%A1%D8%A9-%D8%AA%D8%B4%D8%A7%D8%B1%D9%83-%D8%A8%D9%85%D8%B9%D8%B1%D8%B6-%D8%A7%D9%84%D8%B4%D8%B1%D9%82%D9%8A%D8%A9-%D9%84%D9%84%D9%83%D8%AA%D8%A7%D8%A8-66-%D8%A3%D9%84%D9%81-%D9%85%D8%B3%D8%AA%D9%81%D9%8A%D8%AF-%D9%85%D9%86-%D9%85%D8%A8%D8%A7%D8%AF%D8%B1%D8%A7%D8%AA%D9%87%D8%A7",
    date: "17 08 1444 هـ",
  },
]
const News = () => {
  let PageSize = 10
  const [currentPage, setCurrentPage] = useState(1)

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    return Data.slice(firstPageIndex, lastPageIndex)
  }, [currentPage])
  return (
    <div>
      <Helmet>
        <title>الأخبار</title>
      </Helmet>
      <Header />
      <body>
        <HeroImg title="الأخبار" />

        <main>
          <br />

          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div className="news" style={{ width: "1000px" }}>
              {currentTableData.map(item => {
                return (
                  <div className="card" style={{ backgroundColor: "rgba(0,0,0,.03);" }}>
                    <a href={item.link} target="_blank">
                      <div className="card-body gallery-img" style={{ backgroundImage: `url("${item.img}")` }}></div>
                      <div className="card-footer text-left">
                        <p className="card-text title-card xsmall text-right" style={{ minHeight: "2lh" }}>
                          {item.title} 
                        </p>
                        <p className="card-text s16 detail-card xsmall">
                          {item.date}
                          <i class="fa fa-long-arrow-left mx-2" style={{ color: "#79121f;" }} aria-hidden="true"></i>
                        </p>
                      </div>
                    </a>
                  </div>
                )
              })}
            </div>
          </div>
          <br />
          <br />
        </main>
        <Footer />
      </body>
    </div>
  )
}
export default News
